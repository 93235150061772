import styled from "styled-components";

export const Root = styled.form`
  display: flex;
  position: relative;
  width: 100%;
`;

export const Status = styled.span`
  position: absolute;
  bottom: -20px;
  color: ${p => p.$isShop ? '#e9dfd0' : '#d79d5c'};
  font-size: 11px;
`;

export const InputLabel = styled.label`
  position: absolute;
  top: -20px;
  color: ${p => p.$isShop ? '#e9dfd0' : '#d79d5c'};
  font-size: 11px;
  font-size: 14px;
`;

export const Input = styled.input`
  flex-grow: 1;
  height: 42px;
  margin-right: 12px;
  padding: 0 16px;
  color: #141f35;
  background-color: #e9dfd0;
  font-size: 18px;
  border: 0 none;

  &::placeholder {
   color: color-mix(in srgb, #141f35 70%, transparent);
  }
`;

export const Submit = styled.button`
  height: 42px;
  padding: 0 16px;
  color: ${p => p.$isShop ? '#e9dfd0' : '#ce9b61'};
  font-family: "DharmaGothic";
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  line-height: 42px;
  border: 1px solid ${p => p.$isShop ? '#e9dfd0' : '#ce9b61'};
  white-space: nowrap;
`;
