import React, { useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import { Portal } from 'react-portal';
import FocusLock from 'react-focus-lock';

import {
  Root,
  Container,
  NavList,
  NavLink,
  MobileButton,
} from './MobileMenu.style';

import useLockBodyScroll from '../../hooks/useLockBodyScroll';

import SocialList from '../SocialList/SocialList';
import { MobileButtonItem } from '../Header/Header.style';

const MobileMenu = ({ onClose, isSlim, returnFocusRef }) => {
  useLockBodyScroll();

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose, returnFocusRef]);

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      if (returnFocusRef.current) {
        returnFocusRef.current.focus();
      }
    }, 0);
  };

  return (
    <Portal>
      <FocusLock>
        <Root
          $isSlim={isSlim}
          role="dialog"
          aria-modal="true"
          aria-label="Mobile navigation menu"
          id="mobile-menu"
        >
          <MobileButton
            type="button"
            aria-label="Toggle Mobile Menu"
            aria-expanded={true}
            aria-controls="mobile-menu"
            onClick={handleClose}
            $isSlim={isSlim}
          >
            <MobileButtonItem $menuIsShow={true} />
            <MobileButtonItem $menuIsShow={true} />
            <MobileButtonItem $menuIsShow={true} />
            <MobileButtonItem $menuIsShow={true} />
          </MobileButton>
          <Container>
            <NavList>
              <li>
                <NavLink
                  as={Link}
                  to="/"
                  activeClassName="--active"
                  onClick={onClose}
                >
                  HOME
                </NavLink>
              </li>
              <li>
                <NavLink
                  as={Link}
                  to="/#product"
                  activeClassName="--active"
                  onClick={onClose}
                >
                  PRODUCT
                </NavLink>
              </li>
              <li>
                <NavLink
                  as={Link}
                  to="/history"
                  activeClassName="--active"
                  partiallyActive={true}
                  onClick={onClose}
                >
                  HISTORY
                </NavLink>
              </li>
              <li>
                <NavLink
                  as={Link}
                  to="/recipes"
                  activeClassName="--active"
                  partiallyActive={true}
                  onClick={onClose}
                >
                  RECIPES
                </NavLink>
              </li>
              <li>
                <NavLink
                  as={Link}
                  to="/shop"
                  activeClassName="--active"
                  partiallyActive={true}
                  onClick={onClose}
                >
                  SHOP
                </NavLink>
              </li>
              <li>
                <SocialList />
              </li>
            </NavList>
          </Container>
        </Root>
      </FocusLock>
    </Portal>
  );
};

export default MobileMenu;
