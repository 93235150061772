import React from 'react';
import { Helmet } from 'react-helmet';

const metadata = {
  home: {
    title: 'Home | James Ownby Reserve',
    description: 'Welcome to the homepage of James Ownby Reserve!',
  },
  history: {
    title: 'History | James Ownby Reserve',
    description: 'Learn more about the rich history of James Ownby Reserve.',
  },
  recipes: {
    title: 'Recipes | James Ownby Reserve',
    description: 'Explore delicious recipes crafted with James Ownby Reserve.',
  },
  recipe: {
    title: 'Recipe | James Ownby Reserve',
    description: 'Discover a special recipe featuring James Ownby Reserve.',
  },
  contact: {
    title: 'Contact | James Ownby Reserve',
    description: 'Get in touch with us for more information and inquiries.',
  },
  privacy: {
    title: 'Privacy Policy | James Ownby Reserve',
    description: 'Read our privacy policy and how we handle your data.',
  },
  terms: {
    title: 'Terms & Conditions | James Ownby Reserve',
    description: 'Review the terms and conditions of using our website.',
  },
  cookie: {
    title: 'Cookie Policy | James Ownby Reserve',
    description: 'Learn how we use cookies to enhance your experience.',
  },
  locator: {
    title: 'Store Locator | James Ownby Reserve',
    description: 'Find the nearest store that sells James Ownby Reserve.',
  },
  'media-press': {
    title: 'Media & Press | James Ownby Reserve',
    description: 'Check out the latest media coverage and press releases.',
  },
  'media-press-item': {
    title: 'Media Press Item | James Ownby Reserve',
    description: 'Detailed view of a media or press article related to us.',
  },
  shop: {
    title: 'Shop | James Ownby Reserve',
    description: 'Browse and purchase James Ownby Reserve products.',
  },
  'cookie-policy': {
    title: 'Cookie Policy | James Ownby Reserve',
    description: 'Find out how we use cookies on our website.',
  },
  'accessibility': {
    title: 'Accessibility Statement | James Ownby Reserve',
    description: 'Find out accessibility statement on our website.',
  },
  '404': {
    title: 'Page Not Found | James Ownby Reserve',
    description: 'Oops! The page you are looking for does not exist.',
  },
};

const SEO = ({ pageName }) => {
  const meta = metadata[pageName] || {
    title: 'Home | James Ownby Reserve',
    description: 'Welcome to the homepage of James Ownby Reserve!',
  };

  return (
    <Helmet>
      <html lang="en-US" />
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:type" content="website" />
    </Helmet>
  );
};

export default SEO;
