import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'gatsby';
import { Link as Scroll } from 'react-scroll';

import {
  Root,
  NavList,
  NavLink,
  Logo,
  Ellipsis,
  MobileButton,
  MobileButtonItem,
  MobileLogo,
  SocialListWrapper,
  Nav,
} from './Header.style';

// import LogoSrc from "../../images/logo-gold.svg";
import LogoSrc from '../../images/logo-footer.svg';

import DropdownPortal from '../DropdownPortal/DropdownPortal';
import MobileMenu from '../MobileMenu/MobileMenu';
import SocialList from '../SocialList/SocialList';

const Header = ({ productIsActive, isHomepage }) => {
  const headerRef = useRef();
  const mobileButtonRef = useRef();
  const [menuIsShow, setMenuIsShow] = useState(false);
  const [isSlim, setIsSlim] = useState(false);

  const handleScroll = () => {
    if (headerRef.current) {
      window.scrollY > headerRef.current.getBoundingClientRect().bottom
        ? setIsSlim(true)
        : setIsSlim(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  return (
    <header>
      <a href="#main" className="skip-link">
        Skip to main content
      </a>
      <Root ref={headerRef} $isSlim={isSlim}>
        <MobileButton
          ref={mobileButtonRef}
          type="button"
          aria-label="Toggle Mobile Menu"
          aria-expanded={menuIsShow}
          aria-controls="mobile-menu"
          onClick={() => setMenuIsShow(!menuIsShow)}
        >
          <MobileButtonItem $menuIsShow={menuIsShow} />
          <MobileButtonItem $menuIsShow={menuIsShow} />
          <MobileButtonItem $menuIsShow={menuIsShow} />
          <MobileButtonItem $menuIsShow={menuIsShow} />
        </MobileButton>

        <MobileLogo
          as={Link}
          to="/"
          aria-label="James Ownby Reserve – Go to Homepage"
        >
          <Logo src={LogoSrc} alt="James Ownby Reserve logo" $isSlim={isSlim} />
        </MobileLogo>
        <Nav aria-label="James Ownby Reserve links">
          <NavList>
            <li>
              <NavLink
                as={Link}
                to="/#product"
                className={productIsActive ? '--active' : ''}
                onClick={() => {
                  setTimeout(() => {
                    const targetEl = document.getElementById('reserve_note');
                    if (targetEl) {
                      targetEl.focus();
                    }
                  }, 100);
                }}
              >
                PRODUCT
              </NavLink>
            </li>
            <li>
              <NavLink
                as={Link}
                to="/history"
                activeClassName="--active"
                partiallyActive={true}
              >
                HISTORY
              </NavLink>
            </li>
            <li>
              <NavLink
                as={Link}
                to="/"
                aria-label="James Ownby Reserve – Go to Homepage"
              >
                <Logo
                  src={LogoSrc}
                  alt="James Ownby Reserve logo"
                  $isSlim={isSlim}
                />
              </NavLink>
            </li>
            <li>
              <NavLink
                as={Link}
                to="/recipes"
                activeClassName="--active"
                partiallyActive={true}
              >
                RECIPES
              </NavLink>
            </li>
            <li>
              <NavLink
                as={Link}
                to="https://olesmoky.com/collections/james-ownby-reserve/products/james-ownby"
                activeClassName="--active"
                partiallyActive={true}
                target="_blank"
              >
                SHOP
              </NavLink>
              {/* <NavLink href="https://shop.jamesownbyreserve.com/">
            SHOP
          </NavLink> */}
            </li>
          </NavList>
        </Nav>

        <DropdownPortal
          offset={isSlim ? [0, 7] : [0, 16]}
          triggerContent={
            <Ellipsis>
              <svg
                height="1em"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="#d1914c"
                  d="M 14.198 20.803 C 14.198 22.016 13.214 23 12 23 C 10.787 23 9.803 22.016 9.803 20.803 C 9.803 19.589 10.787 18.605 12 18.605 C 13.214 18.605 14.198 19.589 14.198 20.803 Z M 14.198 11.999 C 14.198 13.213 13.214 14.197 12 14.197 C 10.787 14.197 9.803 13.213 9.803 11.999 C 9.803 10.786 10.787 9.802 12 9.802 C 13.214 9.802 14.198 10.786 14.198 11.999 Z M 14.198 3.197 C 14.198 4.411 13.214 5.395 12 5.395 C 10.787 5.395 9.803 4.411 9.803 3.197 C 9.803 1.984 10.787 1 12 1 C 13.214 1 14.198 1.984 14.198 3.197 Z"
                />
              </svg>
            </Ellipsis>
          }
          dropdownContent={() => (
            <div style={{ minWidth: '230px' }}>
              <ul>
                <li>
                  <DropdownPortal.Item
                    className="--link"
                    activeClassName="--active"
                    as={Link}
                    to="/contact"
                  >
                    CONTACT
                  </DropdownPortal.Item>
                </li>
                <li>
                  <DropdownPortal.Item
                    className="--link"
                    activeClassName="--active"
                    as={Link}
                    to="/privacy"
                  >
                    Privacy Policy
                  </DropdownPortal.Item>
                </li>
                <li>
                  <DropdownPortal.Item
                    className="--link"
                    activeClassName="--active"
                    as={Link}
                    to="/terms"
                  >
                    TERMS & CONDITIONS
                  </DropdownPortal.Item>
                </li>
              </ul>
              <SocialListWrapper>
                <SocialList />
              </SocialListWrapper>
            </div>
          )}
        />

        {menuIsShow && (
          <MobileMenu
            onClose={() => setMenuIsShow(!menuIsShow)}
            isSlim={isSlim}
            returnFocusRef={mobileButtonRef}
          />
        )}
      </Root>
    </header>
  );
};

export default Header;
