import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: ${p => p.$isSlim ? '47px' : '66px'};
  padding-right: 32px;
  padding-left: 12px;
  background-color: #141f35;
  font-family: "GreatWestern";
  z-index: 1000;

  &::before {
    position: absolute;
    bottom: -7px;
    left: 0;
    display: block;
    content: "";
    background-position: -20px -14px;
    width: 100%;
    height: 7px;
    z-index: 1;

    @media (max-width: 1023px) {
      background-image: radial-gradient(circle, transparent 5px, #141f35 5px);
      background-size: 14px 14px;
    }

    @media (min-width: 1024px) {
      background-image: radial-gradient(circle, transparent 6px, #141f35 7px);
      background-size: 20px 14px;
    }
  }
`;

export const Nav = styled.nav`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const NavList = styled.ul`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 1367px;
  list-style: none;

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const NavLink = styled.a`
  color: #d79d5c;
  font-size: 13px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  cursor: pointer;

  &.--active {
    ${'' /* color: #e9dfd0; */}
    border-bottom: 1px solid;
  }
`;

export const Logo = styled.img`
  height: ${p => p.$isSlim ? '20px' : '26px'};
  margin-top: 3px;
`;

export const Ellipsis = styled.div`
  width: 32px;
  height: 32px;
  font-size: 24px;

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const MobileButton = styled.button`
  position: absolute;
  left: 24px;
  width: 32px;
  height: 32px;
  transform: rotate(0deg);
  transition: 500ms ease-in-out;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const MobileButtonItem = styled.span`
  display: block;
  position: absolute;
  left: 4px;
  width: 24px;
  height: 4px;
  background-color: #d1914c;
  opacity: 1;
  transform: rotate(0deg);
  transition: 250ms ease-in-out;

  &:nth-child(1) {
    top: ${p => (p.$menuIsShow ? "14px" : "6px")};
    width: ${p => p.$menuIsShow && "0%"};
    left: ${p => p.$menuIsShow && "50%"};
  }

  &:nth-child(2),
  &:nth-child(3) {
    top: 14px;
  }

  &:nth-child(2) {
    transform: ${p => p.$menuIsShow && "rotate(45deg)"};
  }

  &:nth-child(3) {
    transform: ${p => p.$menuIsShow && "rotate(-45deg)"};
  }

  &:nth-child(4) {
    top: ${p => (p.$menuIsShow ? "14px" : "22px")};
    width: ${p => p.$menuIsShow && "0%"};
    left: ${p => p.$menuIsShow && "50%"};
  }
`;

export const MobileLogo = styled.a`
  @media (max-width: 639px) {
    width: 200px;
  }

  @media (max-width: 1023px) {
    margin-left: 48px;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const SocialListWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  padding: 8px;
`;
